/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, navigate } from 'gatsby';
import { fr } from 'date-fns/esm/locale';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, Row, Col, useScreenClass } from 'react-grid-system';
import {
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  subMonths,
  addMonths,
} from 'date-fns';

// APIs
import API, { JALIOS_EVENTS } from '../../../shared/APIs';

// Assets
import UP_BTN from '../../../images/index/events-section/calendar/up.svg';
import DOWN_BTN from '../../../images/index/events-section/calendar/down.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Calendar                                  */
/* -------------------------------------------------------------------------- */

function Calendar({ showAllEvents, showNumberOfEvents }) {
  /* ------------------------------ Hooks ----------------------------------- */
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeDate, setActiveDate] = useState(new Date());
  const [eventDays, setEventDays] = useState([]);
  const [jaliosEventsDays, setJaliosEventsDays] = useState();
  const [eventData, setEventData] = useState([]);
  const [eventJaliosData, setEventJaliosData] = useState([]);

  /* -------------------------------- CONSTS -------------------------------- */

  const screenClass = useScreenClass();
  const IS_MOBILE = ['xs', 'sm', 'md'].includes(screenClass);

  /* ****************************** HELPER ********************************* */
  // GET ALL JALIOS DATE EVENTS
  async function fetchJaliosAllEventsDates() {
    try {
      const { data } = await axios(JALIOS_EVENTS, {
        headers: {
          Accept: 'application/json',
        },
      });

      const JALIOS_EVENTS_DATES = data.dataSet
        ?.filter((event) => event.class === 'generated.EvenementUGPO')
        ?.map((event) => new Date(event.startDate));
      setJaliosEventsDays(JALIOS_EVENTS_DATES);
      setEventJaliosData(
        data.dataSet?.filter(
          (event) => event.class === 'generated.EvenementUGPO',
        ),
      );
    } catch (error) {
      setEventJaliosData([]);
    }
  }

  // GET ALL DATE EVENTS
  useEffect(() => {
    axios
      .get(API('events'))
      .then(({ data: { data } }) => {
        setEventData(data);
        const EVENTS = data?.map((event) => new Date(event.attributes.date));
        setEventDays(EVENTS);
      })
      .catch((error) => error);
    fetchJaliosAllEventsDates();
  }, []);

  /* ------------------------------- CALLBACK ------------------------------ */
  // GET EVENT DETAILS ONCLICK CALENDAR
  const getDetailEvent = (cloneDate) => {
    setSelectedDate(cloneDate);
    // GET EVENT DATA BY DATE EVENT
    const SELECTED_DATE = format(cloneDate, 'yyyy-MM-dd', { locale: fr });
    let outPut;
    const StrapiIndex = eventData?.findIndex((item) => {
      return item?.attributes?.date === SELECTED_DATE;
    });
    if (StrapiIndex !== -1) {
      outPut = { Source: 'Strapi', event: eventData[StrapiIndex] };
    }
    const JaliosIndex = eventJaliosData?.findIndex((item) => {
      const DateEvent = new Date(item.startDate);
      const FormatDate = format(DateEvent, 'yyyy-MM-dd', { locale: fr });
      return FormatDate === SELECTED_DATE;
    });

    if (JaliosIndex !== -1) {
      outPut = { Source: 'Jalios', event: eventJaliosData[JaliosIndex] };
    }
    if (outPut) {
      navigate('/details-event', {
        state: outPut,
      });
    }
  };

  /* ****************************** HELPERS ******************************** */
  // GET WEEK DAYS
  function getWeekDaysNames() {
    const WEEK_START_DATE = startOfWeek(activeDate);
    const WEEK_DAYS = [];
    for (let day = 0; day < 7; day++) {
      WEEK_DAYS.push(
        <div className="week-days day">
          {format(addDays(WEEK_START_DATE, day), 'E', { locale: fr })}
        </div>,
      );
    }
    return <Container className="week-container fluid">{WEEK_DAYS}</Container>;
  }

  // GENERATE DATES FOR CURRENT WEEK
  function generateDatesForCurrentWeek(date) {
    let CURRENT_DATE = date;
    const WEEK = [];
    for (let day = 0; day < 7; day++) {
      const CLONE_DATE = CURRENT_DATE;
      const EVENTS_CLASSNAME = eventDays?.some((event) =>
        isSameDay(event, CURRENT_DATE),
      )
        ? 'event-day'
        : '';
      const JALIOS_EVENTS_CLASSNAME = jaliosEventsDays?.some((event) =>
        isSameDay(event, CURRENT_DATE),
      )
        ? 'event-day'
        : '';

      WEEK.push(
        <Container
          className={`day ${
            isSameMonth(CURRENT_DATE, activeDate) ? '' : 'inactive-day'
          } ${
            isSameDay(CURRENT_DATE, selectedDate) ? 'selected-day' : ''
          } ${EVENTS_CLASSNAME} ${JALIOS_EVENTS_CLASSNAME}
          ${isSameDay(CURRENT_DATE, new Date()) ? 'today' : ''}
          ${
            isSameDay(CURRENT_DATE, new Date()) || EVENTS_CLASSNAME
              ? 'today-is-event-day'
              : ''
          } fluid`}
          onClick={() => {
            getDetailEvent(CLONE_DATE);
          }}
        >
          {format(CURRENT_DATE, 'd', { locale: fr })}
        </Container>,
      );
      CURRENT_DATE = addDays(CURRENT_DATE, 1);
    }
    return <>{WEEK}</>;
  }

  // GET DATES
  function getDates() {
    const START_OF_THE_SELECTED_MONTH = startOfMonth(activeDate);
    const END_OF_THE_SELECTED_MONTH = endOfMonth(activeDate);
    const START_DATE = startOfWeek(START_OF_THE_SELECTED_MONTH);
    const END_DATE = endOfWeek(END_OF_THE_SELECTED_MONTH);

    let currentDate = START_DATE;

    const ALL_WEEKS = [];

    while (currentDate <= END_DATE) {
      ALL_WEEKS.push(
        generateDatesForCurrentWeek(currentDate, selectedDate, activeDate),
      );
      currentDate = addDays(currentDate, 7);
    }
    return <div className="week-container">{ALL_WEEKS}</div>;
  }

  // GET THE NUMBERS OF EVENTS FOR CURRENT MONTH
  function getNumebrEvents() {
    const ALL_WEEKS = getDates();
    const GET_EVENT_DAYS = ALL_WEEKS?.props?.children?.map((eventWeek) => {
      const FIND_EVENT_DAY = eventWeek?.props?.children?.filter((eventDay) =>
        eventDay.props.className.includes('event-day'),
      );
      return FIND_EVENT_DAY.length;
    });
    const NUMBER_OF_EVENTS = GET_EVENT_DAYS.reduce((a, b) => a + b, 0);
    return (
      <>
        {NUMBER_OF_EVENTS === 1 ? (
          <h6 className="number-of-events">{`${NUMBER_OF_EVENTS} Evènement`}</h6>
        ) : (
          ''
        )}
        {NUMBER_OF_EVENTS > 1 ? (
          <h6 className="number-of-events">{`${NUMBER_OF_EVENTS} Evènements`}</h6>
        ) : (
          ''
        )}
      </>
    );
  }

  // GET MONTH
  function getMonthSelecor() {
    return (
      <Container className="months flex flex-column items-center fluid">
        <Container
          className="months__icons fluid"
          onClick={() => setActiveDate(subMonths(activeDate, 1))}
        >
          <img src={UP_BTN} alt="previous icon" />
        </Container>

        <h2 className="months__current-month">
          {format(activeDate, 'MMMM yyyy', { locale: fr })}
        </h2>
        {showNumberOfEvents && getNumebrEvents()}

        <Container
          className="months__icons fluid"
          onClick={() => setActiveDate(addMonths(activeDate, 1))}
        >
          <img src={DOWN_BTN} alt="previous icon" />
        </Container>
      </Container>
    );
  }

  /* ****************************** RENDERING ********************************* */
  return (
    <Container className="calendar">
      <Row>
        <Col
          className="flex flex-column justify-center"
          xs={12}
          md={showAllEvents ? 3 : 4}
        >
          {getMonthSelecor()}
        </Col>
        <Col
          className={IS_MOBILE ? 'flex flex-column items-center' : ''}
          md={6}
        >
          <Row>{getWeekDaysNames()}</Row>
          <Row>{getDates()}</Row>
        </Col>
        {showAllEvents && (
          <Col className="flex flex-column justify-center" md={3}>
            <Link
              className="event-list-link flex items-center justify-center fluid"
              to="/evenements"
            >
              <h5>Liste des évènements</h5>
            </Link>
          </Col>
        )}
      </Row>
    </Container>
  );
}

Calendar.propTypes = {
  showAllEvents: PropTypes.string,
  showNumberOfEvents: PropTypes.string,
};

Calendar.defaultProps = {
  showAllEvents: '',
  showNumberOfEvents: '',
};

export default Calendar;
